import React from "react";
import "./styles.scss";
import biudLogo from "@imgs/biud_transparent.png";
import { genLogo } from "@/assets/imgs";
import { isGsGroup } from "@/services/utils";

export default function LoadingPage() {
  const isGs = isGsGroup();

  return (
    <div className="biud-loading-page">
      <img src={isGs ? genLogo : biudLogo} alt="Biud logo" className={`biud-loading ${isGs ? "gen-logo" : ""}`} />
    </div>
  );
}
