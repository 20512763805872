import md5 from "md5";
import jwt_decode from "jwt-decode";
import jwt_encode from "jwt-encode";
import { client } from "@/graphql/apollo";
import { IBusinessGroup } from "@/graphql/types";
import { Chart, CreateDashboard, ChartBar } from "@newcomponentes/CustomIcons";
import { ISelectedBusiness } from "@/pages/CustomersB2B/modals/CustomerDetails/types";
import { isGsGroup } from "./utils";

export const TOKEN_KEY = "app-biud-token";
export const USER_KEY = "app-user-token";
export const TOKEN_TYPE = "app-biud-token-type";
export const COMPANY_SELECTED = "company-selected";
export const COMPANY_OBJECT = "company-object";
export const GROUP_OBJECT = "group-object";
export const COMPANY_NAME_SELECTED = "company-name-selected";
export const COMPANY_IMAGE_SELECTED = "company-image-selected";
export const BIUD_CONNECT = "biud_connect";
export const CNPJ_CONFLICT = false;
export const IS_DATA_SHARING = "data_sharing";
export const PRODUCT_NOT_REVIEWED = "product_not_reviwed";
export const CALENDLY_URI = "calendly_uri";
export const ITEM_CACHE = "item_cache";
export const GENERAL_CACHE = "general_cache";
export const MENU_DISPOSITION = "menu_preference";

export const companyIsSelected = localStorage.getItem(COMPANY_SELECTED) !== null;
export const isAuthenticated = localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const login = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const getUser = (): { name: string; username: string; roles: string[]; phone: string } | null => {
  const token = localStorage.getItem(USER_KEY);

  if (!token) {
    return null;
  }

  return jwt_decode(token);
};

export const getUserFirstName = (): string => {
  const userName = getUser();

  if (!userName?.name) {
    return "";
  }

  return userName.name.split(" ")[0];
};

export const getUserLastName = (): string => {
  const userName = getUser();

  if (!userName?.name) {
    return "";
  }

  const nameSplited = userName.name.split(" ");

  if (nameSplited.length === 1) {
    return "";
  }

  return nameSplited.at(-1) || "";
};

export const setUser = (userInfo: { username: string; roles: string[] }) => {
  const secret = "secret";

  const token = jwt_encode(userInfo, secret);
  localStorage.setItem(USER_KEY, token);
};

export const getMemberKit = (): string | null => {
  const token = localStorage.getItem("bmk");

  if (!token) {
    return null;
  }

  return jwt_decode(token);
};

export const setMemberKit = (url: string) => {
  const secret = "secret";

  const token = jwt_encode(url, secret);
  localStorage.setItem("bmk", token);
};

export const productNotReviwed = (isProduct: any) => {
  localStorage.setItem(PRODUCT_NOT_REVIEWED, isProduct);
};

export const companyName = () => {
  return localStorage.getItem(COMPANY_NAME_SELECTED);
};

export const setIsDataSharing = (bool: any) => {
  localStorage.setItem(IS_DATA_SHARING, bool);
};

export const isDataSharing = () => {
  return localStorage.getItem(IS_DATA_SHARING) === "true";
};

export const setCompany = (company: any) => {
  localStorage.removeItem(COMPANY_OBJECT);
  localStorage.setItem(COMPANY_OBJECT, JSON.stringify(company));
};

export const setGroup = (group: any) => {
  localStorage.removeItem(GROUP_OBJECT);
  localStorage.setItem(GROUP_OBJECT, JSON.stringify(group));
};

export const selectCompany = (companyId: any) => {
  localStorage.removeItem(COMPANY_SELECTED);
  localStorage.setItem(COMPANY_SELECTED, companyId);
};

export const deleteCompany = () => {
  localStorage.removeItem(COMPANY_SELECTED);
};

export const selectNameCompany = (companyName: any) => {
  localStorage.removeItem(COMPANY_NAME_SELECTED);
  localStorage.setItem(COMPANY_NAME_SELECTED, companyName);
};

export const selectImageCompany = (companyImage: any) => {
  localStorage.removeItem(COMPANY_IMAGE_SELECTED);
  // Todo image firebase
  // const res = getUrlImagesFB(companyImage)
  localStorage.setItem(COMPANY_IMAGE_SELECTED, companyImage);
};

export const selectNewCompany = (company: any) => {
  localStorage.removeItem(COMPANY_SELECTED);
  localStorage.setItem(COMPANY_SELECTED, company);
};

export const setCnpjConflict = (bool: any) => {
  // @ts-expect-error TS(2345): Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
  localStorage.setItem(CNPJ_CONFLICT, bool);
};

export const biudConnectSet = (bool: any) => {
  localStorage.setItem(BIUD_CONNECT, bool);
};

export const isBiudConnect = () => {
  return localStorage.getItem(BIUD_CONNECT) === "true";
};

export const resetGroupSelected = () => {
  localStorage.removeItem(COMPANY_NAME_SELECTED);
  localStorage.removeItem(COMPANY_SELECTED);
  localStorage.removeItem(COMPANY_OBJECT);
  localStorage.removeItem(COMPANY_IMAGE_SELECTED);
  localStorage.removeItem(GROUP_OBJECT);
};

export const logout = () => {
  const isGs = isGsGroup();
  client.resetStore();
  localStorage.clear();
  if (isGs) return window.location.replace("/login/GEN");
  window.location.replace("/login");
};

export const companySelected = () => Number(localStorage.getItem(COMPANY_SELECTED));

export const groupSelected = (): number | null => getGroup()?.id || null;

export const companyImageSelected = () => {
  return localStorage.getItem(COMPANY_IMAGE_SELECTED);
};

export const companyImageClear = () => {
  return localStorage.removeItem(COMPANY_IMAGE_SELECTED);
};

export const getCompany: () => ISelectedBusiness | null = () => {
  const companyObject = localStorage.getItem(COMPANY_OBJECT);
  if (!companyObject) return null;
  return JSON.parse(companyObject);
};

export const getGroup = (): IBusinessGroup | null => {
  const groupObject = localStorage.getItem(GROUP_OBJECT);
  if (!groupObject) return null;
  return JSON.parse(groupObject);
};

export const getGroupName = (): string | null => {
  const groupName = getGroup()?.name;
  if (!groupName) return null;
  return groupName.match(/^Rede /) ? groupName : `Rede ${groupName}`;
};

export const isGeneralShopGroup = (): boolean => {
  const groupName = getGroup()?.name;
  if (!groupName) throw new Error("Nenhuma rede selecionada");
  return groupName.includes("GS");
};

export const canRequestHotleadsActivation = () => {
  return Boolean(getCompany()?.activationRequestParent);
};

export const getGroupNameWithoutRede = (): string | null => {
  return getGroupName()?.split(" ").slice(1).join(" ") || null;
};

export const getGroupList = () => {
  return getGroup()?.businesses.map((item: any) => ({
    id: item.id,
    name: item.name,
  }));
};

export const getBusinessIds = () => {
  return getGroup()?.businesses.map((biz: any) => biz.id);
};

export const cnpjConflict = () => {
  // @ts-expect-error TS(2345): Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
  return localStorage.getItem(CNPJ_CONFLICT);
};

export const getTokenDecode = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (!token) {
    return null;
  }

  return jwt_decode(token);
};

export const isAdmin = () => {
  const user = getUser();

  if (!user) {
    return false;
  }

  return user.roles.includes("ROLE_ADMIN");
};

export const isComercial = () => {
  const user = getUser();

  if (!user) {
    return false;
  }

  return user.roles.includes("ROLE_SELLER");
};

export const isEmailBiuder = () => {
  const decodedToken: any = getTokenDecode();

  if (!decodedToken) {
    return false;
  }

  return decodedToken.username.includes("@biud.com.br");
};

export const isFinancialUser = (isValidRoleAdmin = true) => {
  const user = getUser();

  if (!user) {
    return false;
  }

  if (isValidRoleAdmin) {
    return user.roles.includes("ROLE_ADMIN") || user.username === "financeiro@biud.com.br";
  }

  return user.username === "admin@biud.com.br" || user.username === "financeiro@biud.com.br";
};

export const getCurrentUserEmail = () => {
  const decodedToken: any = getTokenDecode();

  if (!decodedToken) {
    return null;
  }

  return decodedToken.username;
};

export const addUserType = (type: any) => {
  const md5Type = md5(type);
  localStorage.removeItem(TOKEN_TYPE);
  localStorage.setItem(TOKEN_TYPE, JSON.stringify(md5Type));
};

export const existsUserType = () => {
  // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
  const token = JSON.parse(localStorage.getItem(TOKEN_TYPE));
  return !!token;
};

export const isOwner = () => {
  // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
  const token = JSON.parse(localStorage.getItem(TOKEN_TYPE));
  const ownerMd5 = md5("OWNER");
  return token === ownerMd5;
};

export const getUserType = () => {
  // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
  const token = JSON.parse(localStorage.getItem(TOKEN_TYPE));
  const userTypeDict = {
    OWNER: token === md5("OWNER"),
    WORKER: token === md5("WORKER"),
    MANAGER: token === md5("MANAGER"),
    CUSTOMER_SERVICE: token === md5("CUSTOMER_SERVICE"),
    SELLER: token === md5("SELLER"),
    ADMIN: isAdmin(),
  };
  return Object.entries(userTypeDict);
};

export const isWorker = () => {
  // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
  const token = JSON.parse(localStorage.getItem(TOKEN_TYPE));
  const workerMd5 = md5("WORKER");
  return token === workerMd5;
};

export const isManager = () => {
  // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
  const token = JSON.parse(localStorage.getItem(TOKEN_TYPE));
  const managerMd5 = md5("MANAGER");
  return token === managerMd5;
};

export const isCustomerService = () => {
  // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
  const token = JSON.parse(localStorage.getItem(TOKEN_TYPE));
  const customerServiceMd5 = md5("CUSTOMER_SERVICE");
  return token === customerServiceMd5;
};

export const isSeller = () => {
  // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
  const token = JSON.parse(localStorage.getItem(TOKEN_TYPE));
  const sellerMd5 = md5("SELLER");
  return token === sellerMd5;
};

export const isProductNotReviwed = () => {
  return localStorage.getItem(PRODUCT_NOT_REVIEWED);
};

export const isCS = () => {
  //TODO: remove mock - salvar chave isCS no localStorage ("true" ou "false")
  localStorage.setItem("isCS", "true");
  return true;
};

export const setCalendlyUri = (object: any) => {
  localStorage.setItem(CALENDLY_URI, JSON.stringify(object));
};

export const setCacheItemObj = (objMap: any) => {
  localStorage.setItem(ITEM_CACHE, JSON.stringify(objMap));
};

export const getCacheItemObj = () => {
  return localStorage.getItem(ITEM_CACHE) ? JSON.parse(localStorage.getItem(ITEM_CACHE) || "") : null;
};

export const setGeneralCache = (objMap: any) => {
  localStorage.setItem(GENERAL_CACHE, JSON.stringify(objMap));
};

export const getGeneralCache = () => {
  return localStorage.getItem(GENERAL_CACHE) ? JSON.parse(localStorage.getItem(GENERAL_CACHE) || "") : null;
};

export const getCalendlyUri = () => {
  // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
  return JSON.parse(localStorage.getItem(CALENDLY_URI)) || {};
};

export const removeCalendlyUri = () => {
  localStorage.removeItem(CALENDLY_URI);
};

export const isRecaptchaEnabled = () => {
  return import.meta.env.VITE_ENV === "prod";
};

const handleDrogaFujiLooker = (lookerBaseUrl: string) => {
  if (isAdmin()) {
    return [
      {
        Icon: ChartBar,
        name: "KPI",
        url: `https://dashboards.keepi.media/share/65ea2cda33c39c001f5e0afd/1?p=true`,
      },
      {
        Icon: CreateDashboard,
        name: "Resultados",
        url: `https://lookerstudio.google.com/embed/reporting/936cc000-951e-47d4-9869-73530d7e983f/page/Y7pUD`,
      },
      {
        Icon: Chart,
        name: "Projeto",
        url: `https://lookerstudio.google.com/embed/reporting/c365c422-4245-4813-b9c5-ca4bb7271369/page/z5USD`,
      },
    ];
  }
  if (isComercial()) {
    return [
      {
        Icon: ChartBar,
        name: "KPI",
        url: `https://dashboards.keepi.media/share/65ea2cda33c39c001f5e0afd/1?p=true`,
      },
    ];
  }

  return [
    {
      Icon: CreateDashboard,
      name: "Resultados",
      url: `${lookerBaseUrl}936cc000-951e-47d4-9869-73530d7e983f/page/Y7pUD`,
    },
    { Icon: Chart, name: "Projeto", url: `${lookerBaseUrl}c365c422-4245-4813-b9c5-ca4bb7271369/page/z5USD` },
  ];
};

export const getAnalyticsUrl = (groupId: number) => {
  if (!groupId) return 0;

  const lookerBaseUrl = "https://lookerstudio.google.com/embed/reporting/";
  const powerBiBaseUrl = "https://app.powerbi.com/reportEmbed?reportId=";
  const powerBiViewUrl = "https://app.powerbi.com/view?r=";

  const businessDict: { [key: number]: string | { Icon: any; name: string; url: string }[] } = {
    3009: `${lookerBaseUrl}374ae5c6-7f01-46d8-ae14-14fb7b9cc2e9/page/kzLWD`, //Bosque
    3023: `${lookerBaseUrl}451329f3-8063-4db5-b1b0-b6bd1f4b7789/page/6LGTD`, //Daju
    2188: handleDrogaFujiLooker(lookerBaseUrl),
    2881: `${lookerBaseUrl}3e69e32d-5bab-4d8a-8f42-ee03f1b8f1f6/page/Xl9VD`, //SushiLoko
    3025: [
      {
        Icon: CreateDashboard,
        name: "Resultados",
        url: `${lookerBaseUrl}f0bf27ca-f519-4e40-8465-a401052ef49f/page/32SUD`,
      },
      { Icon: Chart, name: "Ticket médio", url: `${lookerBaseUrl}d65e5009-48fb-429a-b98c-85d6bd289281/page/YEJoD` },
    ], //PizzaCrek
    2210: `${lookerBaseUrl}ef782482-c095-4eeb-8119-33e56e61d56a/page/JOZUD`, //Rede dos cosmesticos
    3118: `${lookerBaseUrl}7b54db25-dac1-46e0-bb27-c63ddb6bcbd1/page/XIJXD`, //Rede mercado biud
    3004: `${lookerBaseUrl}ac7f28ac-f9b4-486c-a900-c726c18ec0bd/page/DmyXD`, //Rede adarco
    2693: `${lookerBaseUrl}5ade98dd-bf5b-4198-b5d7-a122350dce01/page/0SYXD`, // Associadas Gramado
    2877: `${lookerBaseUrl}f031001d-be9f-4ead-bbc7-4608654a18ab/page/OS2ZD`, // Peça rara
    2307: `${lookerBaseUrl}f49bc31b-acd1-47f8-9505-d62c738c9c29/page/p_3yjqxslc9c`, // Lord
    2966: `${lookerBaseUrl}b6233d51-5dca-401b-aae0-33d9c84ef5ec/page/UThXD`, // Drogaria Americana
    2975: `${lookerBaseUrl}56164dc2-99cb-4fd2-97df-769d0b10b511/page/eflbD`, // Drogarias Ferreira
    2444: [
      {
        Icon: Chart,
        name: "Looker",
        url: `${lookerBaseUrl}12cad2c6-7b23-4038-8631-c3cd97f99900/page/p_pxkrlo2r9c`,
      },
      {
        Icon: Chart,
        name: "Power BI",
        url: `${powerBiViewUrl}eyJrIjoiYWNkZjVmNjgtNWE4ZC00M2I5LTk5NmMtYWJjNjNhMzc5ZTVmIiwidCI6IjNkZGYyNjRkLThkYTQtNGRmYS05YWQ0LTgwOTE1MjRmMWQxZiJ9`,
      },
    ], // GS São Paulo
    2670: `${lookerBaseUrl}84f71d7f-b220-4560-8038-67aeca0095b3/page/ScsqD`, // General Grande São Paulo
    3135: `${powerBiViewUrl}eyJrIjoiZTExNTY2OTQtZTQ3YS00NGJlLWEyMDgtYWQ1YmMyZjJiNDVkIiwidCI6IjNkZGYyNjRkLThkYTQtNGRmYS05YWQ0LTgwOTE1MjRmMWQxZiJ9`, // 1900 pizzaria
    3140: `${lookerBaseUrl}97d5f735-d0b4-49f1-b80b-8de3c14a2247/page/umbhD`, // Shoebiz
    3069: `${powerBiBaseUrl}05ee7132-83f7-4b05-a183-8f37525f5309&autoAuth=true&ctid=3ddf264d-8da4-4dfa-9ad4-8091524f1d1f`, // BIUD QA
    3246: `${powerBiViewUrl}eyJrIjoiNzY2YzYzNzUtZTU4NS00MGZhLThmYjktNjExOGQ1Y2U5OWQyIiwidCI6IjNkZGYyNjRkLThkYTQtNGRmYS05YWQ0LTgwOTE1MjRmMWQxZiJ9`, // Moka
    3236: `${powerBiBaseUrl}d7f566d3-f385-48f1-8dd6-4bc050619bd2&autoAuth=true&ctid=3ddf264d-8da4-4dfa-9ad4-8091524f1d1f`, // GS - Outlet Brasília
    3235: "https://app.powerbi.com/links/cqezDoMEpA?ctid=3ddf264d-8da4-4dfa-9ad4-8091524f1d1f&pbi_source=linkShare", // Shopping Maia
  };

  return businessDict[groupId] || 0;
};

export const setMenuPreference = (value: string) => localStorage.setItem(MENU_DISPOSITION, value);

export const getMenuPreference = (): string | null => localStorage.getItem(MENU_DISPOSITION);

export const getHotleadPrice = () => {
  return 10;
};

export const getIsUserEsgAdminRegional = () => {
  try {
    const email = getCurrentUserEmail();
    return email === "esg-regional@biud.com.br";
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getIsUserEsgAdminNacional = () => {
  try {
    const email = getCurrentUserEmail();
    return email === "esg-nacional@biud.com.br";
  } catch (error) {
    console.error(error);
    return false;
  }
};
export const getUserEsg = () => {
  try {
    const email = getCurrentUserEmail();
    const business = getCompany();
    const group = getGroup();

    const bizCnpj = business?.cnpj || "";
    return (
      email === "jaquelinesouza@biud.com.br" ||
      email === "emaillogin@biud.com" ||
      bizCnpj === "49663628000166" ||
      bizCnpj === "48267133000155" ||
      bizCnpj === "28841110000103" ||
      group?.id === 3069
    );
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getUserBbAgroEsg = () => {
  try {
    const email = getCurrentUserEmail();
    return email === "bbagro@biud.com.br";
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getNetworkName = () => {
  const group = getGroup();
  if (!group) return "";

  if (group.name.includes("Rede")) {
    return group.name.slice(5);
  }

  return group.name;
};
