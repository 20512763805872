import React from "react";
import { useHistory } from "react-router-dom";

export default function Version() {
  const version = import.meta.env.VITE_VERSION ?? [];
  const versionNumber =
    version.includes("release") || version.includes("staging") ? version.replace(/[^\d.]/g, "") : version;
  const history = useHistory();

  return (
    <span
      className="version-info"
      style={{ color: "#A3A3A3" }}
      // onClick={() => history.push("/internal/updates")}
    >
      Versão {versionNumber}
    </span>
  );
}
