import { gql } from "@/graphql/config";

export const CURRENT_USER_AND_BUSINESS_GROUP_QUERY = gql`
  query CurrentUser {
    CurrentUser {
      id
      name
      email
      userBusinessGroups {
        id
        name
        logo
        readonly
        businesses {
          id
          name
          cnpj
          logo
          instagram
          facebook
          siteUrl
          isDataSharingEnabled
          externalId
          operatingAreas {
            id
            name
          }
          activationRequestParent {
            externalId
          }
        }
        groupType
      }
    }
  }
`;

export const CURRENT_USER_NAME_QUERY = gql`
  query CurrentUser {
    CurrentUser {
      name
    }
  }
`;
